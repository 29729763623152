.ba-ph {
    color: #C3FD48;
    font-size: 24px;
    font-family: 'monument_extendedregular';
    margin-bottom: 10px;
}
section.bp-home-roadmap {
    padding: 60px 0px 30px 0px;
}
.rm_box_heading {
    width: 20%;
    float: left;
}
.rm_box_main {
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
}
.rm_box {
    width: 80%;
    float: left;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    margin-top: 25px;
}
.rm_box-inner {
    display: -moz-box;
    display: flex;
    padding-bottom: 11px;
}
.rm_box-inner img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 4px;
}
.rm_box_maindiv {
    border-left: 2px dashed rgb(255 255 255/20%);
    width: 3px;
    height: 98%;
    position: absolute;
    left: 18%;
    top: 27px;
}
.rm_box_mainsec {
    position: relative;
}
.rm_box-inner p {
    margin: 0;
    font-size: 16px;
}
.rm_boxsec {
    float: right;
    width: 80%;
    margin-top: 20px;
    color: #fff;
}
.rm_boxsec p{
    font-size: 16px;
    color: #fff;
}
.line-through p { 
    text-decoration: line-through; 
}

/*Responsive*/

@media screen and (max-width:767px) {
section#roadmap h2 {
    text-align: center;
}
.rm_box_heading {
    width: 100%;
    float: left;
}
.rm_box_maindiv {
    border-left: 2px dashed rgb(255 255 255/20%);
    width: 19px;
    height: 98%;
    position: absolute;
    left: 0;
    top: 8px;
}
.rm_box_main {
    padding-left: 20px;
    display: block;
    float: left;
    width: 100%;
}
.rm_box {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
}
.rm_boxsec {
    float: left;
    width: 100%;
    padding-left: 25px;
}
.rm_box_mainsec {
    overflow: auto;
}

}