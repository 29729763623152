section.bp-migr-sec h4 {
    color: #C3FD48;
    font-size: 24px;
    font-family: 'monument_extendedregular';
    line-height: 1.5;
    padding-top: 20px;
}
section.bp-migr-sec p {color: #fff;max-width: 700px;margin: 0 auto;}
section.bp-migr-sec {
    padding: 30px 0 50px 0;
}
section.bp-migr-sec {
    text-align: center;
}
.bp-migr-sec-inner a {
    background-color: #C3FD48;
    width: 100%;
    display: inline-block;
    color: #08130F;
    font-size: 16px;
    padding: 7px 15px;
    border-radius: 5px;
    font-family: 'gothambold';
}
.bp-migr-sec-inner a:hover {
    background-color: #85bb14;
}
.bp-migr-sec-inner h3 {
    padding: 25px 0 0 0;
}
.bp-migr-sec-inner-main {
    max-width: 700px;
    margin: 0 auto;
    padding: 60px 0 50px 0;
}
ul.migrate_steps {
    padding: 0;
    width: 100%;
    max-width: 630px;
    margin: 0 auto;
}
ul.migrate_steps li{
    color: #FFF;
    font-size: 18px;
    padding: 8px 0 8px 15px;
    display: inline-block;
    position: relative;
    list-style: none;
}

ul.migrate_steps li::before{
    content: "";
    width: 8px;
    height: 8px;
    background-color: white;
    position: absolute;
    border-radius: 50px;
    top: 18px;
    left: 0px;
}
@media screen and (max-width:767px) {
.bp-migr-sec-inner a {
    width: auto;
    min-width: 250px;
}
.bp-migr-sec-inner h3 {
    padding: 0px 0 0 0;
}
.bp-migr-sec-inner {
    padding-bottom: 25px;
}
.bp-migr-sec-inner a {
    margin-bottom: 10px;
}
}