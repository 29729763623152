.bp-home-banner {
    background-image:url(../../assets/images/hero_banner.jpg);
    min-height: 550px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding: 110px 0px 100px 0px;
    margin-top: -2px;
}
section.bp-home-banner h1 {
    font-size: 48px;
    color: #C3FD48;
    line-height: 1.2;
}
section.bp-home-banner p {
    color: #FFF;
    font-size: 18px;    padding: 5px 0 5px 0;
}
ul.bp-hoba-btn a {
    font-size: 14px;
    line-height: 30px;
    transition: .2s;
    color: #08130F;
    background: #C3FD48;
    border-radius: 5px;
    padding: 9px 30px 9px 30px;
    min-width: 200px;
    display: block;
    text-align: center;
    font-family: 'gothambold';
}
ul.bp-hoba-btn {
    list-style: none;
    padding: 0px;
    padding-top: 10px;
    margin-bottom: 0px;
}
ul.bp-hoba-btn li {
    display: inline-block;
    padding-right: 24px;
}
ul.bp-hoba-btn li a:hover {
    background-color: #85bb14;
}
section.bp-home-banner:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(90deg, rgba(8, 19, 15, 0.79) 45%, rgba(8, 19, 15, 0)70%);
    top: 1px;
    z-index: 9;
    left:0;
}
.bp-hoba-main {
    position: relative;
    z-index: 11;
}
ul.bp-hoba-btn img {
    margin-right: 8px;
}
/*Resposive*/
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .bp-home-banner {
        padding: 80px 0px;
    }
}
@media screen and (max-width:991px){
	section.bp-home-banner h1 {
        font-size: 40px;
    }
	section.bp-home-banner {
        text-align: center;
    }
	section.bp-home-banner .col-md-8 {
        width: 100%;
    }
    section.bp-home-banner:after {
        background: rgb(23 47 38 / 35%);
    }
}

@media screen and (max-width:1024px){
	.bp-home-banner {
		height: 450px;
		min-height: auto;
		background-size: cover;
		background-position: bottom;
	}
}
@media screen and (max-width:767px) {
    section.bp-home-banner {
        height: unset;
        min-height: unset;
        padding-top: 60px;
        padding-bottom: 40px;
    }
    section.bp-home-banner h1 {
        font-size: 30px;
    }
    ul.bp-hoba-btn li {
        padding: 0px 8px 15px 8px;
    }
}
@media screen and (max-width:600px) {
	.bp-home-banner {
		height: 280px;
	}

}
@media screen and (max-width:480px) {
	.bp-home-banner {
		height: 215px;
	}

}

