section.bp-clla-banner img {
    width: 100%;
    height: auto;
    /*max-height: 85vh;*/
    object-fit: cover;
    object-position: bottom;
}
.bp-clla-vidbanner video {
    width: 100%;
    object-fit: cover;
    height: 100%;
    margin: 0;
    padding: 0;
}