section.bp-cltaproc-main {
    padding: 70px 0 100px 0;
}
section.bp-clta-main p {
    max-width: 650px;
}
section.bp-clla-banner img {
    width: 100%;
    height: auto;
}
.bp-cltaproc-inner {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 15px;
    padding: 25px 20px;
    text-align: center;
}
.bp-cltaproc-inner h3 {
    margin: 0;    
    font-size: 18px;
}
section.bp-cltaproc-main h2 {
    font-size: 22px;
}
.bp-cltaproc-inner p {
    color: #fff;
    line-height: 20px;
    padding-top: 15px;
}
.bp-cltaproc-input input {
    background-color: unset;
    border: unset;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-family: 'monument_extendedregular';
    line-height: 1.5;
    width: 80%;
    max-width: 70px;outline: none;
}
.bp-cltaproc-label label {
    color: #fff;
    font-size: 18px;
    font-family: 'monument_extendedregular';
    line-height: 1.5;
}
.bp-cltaproc-input {
    width: 50%;
    max-width: 100px;
}
.bp-cltaproc-label {
    width: 50%;
}
.bp-cltaproc-form-inner {
    display: flex;    
    padding: 8px 0 8px 0;
}
span#plus img, span#minus img {
    padding-bottom: 6px;
}
.bp-cltaproc-main input::-webkit-outer-spin-button,
.bp-cltaproc-main input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.bp-cltaproc-sub {
    width: 100%;
    padding: 15px 0px 0px 0px;
}
button.close {
    outline: none;
}
.bp-cltaproc-sub input {
    background-color: #C3FD48;
    color: #08130F;
    font-size: 16px;
    padding: 5px 25px;
    border-radius: 5px;
    transition: .2s;
    border: unset;
    min-width: 200px;
    transition: .2s;
    font-family: 'gothambold';
}
.bp-cltaproc-sub input:hover {
    background-color: #85bb14;
}
.bp-cltaproc-maxbtn button {
    background-color: #C3FD48;
    color: #172F26;
    font-size: 13px;
    font-weight: 700;
    padding: 9px 15px;
    border-radius: 50px;
    transition: .2s;
    border: unset;
    font-family: 'monument_extendedregular';
    margin-left: 8px;
    transition: .2s;
}
.bp-cltaproc-maxbtn button:hover,.cl-btn button:hover {
    background-color: #C3FD48;
}
.bp-cltaproc-id label {
    font-size: 18px;
    color: #fff;
}
select#tokenid {
    background-color: rgb(255 255 255 / 50%);
    border-radius: 5px;
    color: #172F26;
    padding: 5px 10px 5px 10px;
    width: 100%;
     -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background-image:url(../../assets/images/down.png);
        background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 13px;
    outline: none;
}
.bp-tokform {
    padding: 25px 0 0 0;
}
.bp-cltaproc-select-label {
    padding: 29px 0 9px 0;
}
.cl-cpopup {
    text-align: center;
}
.rotate{
    animation: spin 1.5s linear infinite;
}
.cl-cpopup .header {
    text-align: left;
}
.cl-ape-img {
    float: left;
    width: 30%;
}
.cl-succ-mess {
    float: left;
    width: 70%;
    text-align: left;
    padding: 14px 0 0 0;
}
.modal .content .cl-succ-mess p {
    text-align: left;
}
.cl-ape-img-frame:before{
    content: " ";
    background-image: url(../../assets/images/woodframe.png);
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 130px;
}
.cl-ape-img-frame {
    width: 100%;
    position: relative;
}
.cl-ape-img-frame img {
    width: 130px;
    padding: 10px;
}
.cl-btn button {
    background-color: #C3FD48;
    color: #172F26;
    font-size: 18px;
    font-weight: 700;
    padding: 5px 25px;
    border-radius: 50px;
    transition: .2s;
    border: unset;
    min-width: 140px;
    transition: .2s;
}
.cl-btn {
    float: left;
    width: 100%;
    text-align: center;
    padding: 25px 0 25px 0;
}
@keyframes spin {
    0%{
        transform:rotate(0deg);
    }
    100%{
        transform:rotate(1turn);
    }
}
@media screen and (max-width:991px) {
    section.bp-cltaproc-main .col-md-4 {
    width: 50%;
    margin-bottom: 25px;
}
}
@media screen and (max-width:767px) {
    section.bp-cltaproc-main .col-md-4 {
        width: 100%;
    }
    .bp-cltaproc-inner {
        max-width: 330px;margin: 0 auto;
    }
    .cl-ape-img {
    width: 100%;
    text-align: center;
}
.cl-succ-mess {
    width: 100%;
    text-align: center;
}
.modal .content .cl-succ-mess p {
    text-align: center;
}
}
@media screen and (max-width:480px) {
.bp-cltaproc-sub input {
    font-size: 16px;
    font-weight: 700;
    padding: 5px 18px;
    min-width: 160px;
}
}