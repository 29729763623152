section.bp-bnbarmi-main h2 {
    color: #fff;
    text-align: center;
    padding: 0 0 30px 0;
}
section.bp-bnbarmi-main {
    padding: 30px 0px 30px 0px;
}
.bp-bnbarmi-inner h3 {
    color: #C3FD48;
    padding: 10px 0 0 0;
    font-size: 20px;
}
.bp-bnbarmi-inner {
    text-align: center;
    padding: 5px 15px 30px 15px;
}
.bp-bnbarmi-inner p {
    color: #fff;
}
.bp-bnbarmi-inner p span {
    color: #C3FD48;
}
.bp-bnbarmi-inner-sec {
    max-width: 370px;
    margin: 0 auto;
}
section.bp-bnbarmi-main {
    padding: 30px 0px 30px 0px;
}
.bp-bnbarmi-itcon p {
    color: #fff;
    text-align: center;
    text-decoration: inherit;
    font-style: italic;
}
.bp-bnbarmi-itcon {
    padding: 35px 15px 30px 15px;
}
.bp-itth-inbx-head h2 {
    font-size: 20px;
}

