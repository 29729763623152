.bp-treepg-banner{
	background-image:url(../../assets/images/treehousepregateway-min.jpg);
	padding: 250px 10px 250px 1px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
section.bp-treepg-banner {
    text-align: center;
}
/*popup css*/
section.bp-treepg-banner {
    text-align: center;
}
.bp-thpgnm-tew p{
    color: #172F26;
}
.bp-thpgnm-tew {
    max-width: 580px;
    background-color: #fff;
    border-radius: 5px 5px 0px 0px;
}
.bp-thpgnm-tew .content.bp-treepg-pop,.bp-thpgnm-tew .header {
    text-align: left;
}

button.bp-treepg-btn {
    font-size: 26px;
    line-height: 1.5;
    background-color: #C3FD48;
    border: unset;
    border-radius: 5px;
    font-family: 'gothambold';
    color: #08130F;
    padding: 7px 16px;
}
button.bp-treepg-btn:hover {
    background-color: #85bb14;
}
button.bp-treepg-btn img {
    padding-right: 5px;
    margin-top: -4px;
}
a.bp-treepg-popbtn {
    background-color: #C3FD48;
    padding: 10px 25px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #172F26;
}
a.bp-treepg-popbtn img {
    width: 20px;
    margin-top: -2px;
    margin-right: 5px;
}
.content.bp-treepg-pop p {
    text-align: left;
    padding-bottom: 10px;
}
.content.bp-treepg-pop {
    padding: 25px 25px 30px 25px;
}
/*Responsive*/
@media screen and (max-width:991px) {
	button.bp-treepg-btn {
	    font-size: 22px;
	}
	button.bp-treepg-btn img {
	    width: 45px;
	}
    .bp-treepg-banner {
        padding: 200px 10px 200px 10px;
    }
}
@media screen and (max-width:355px) {
    a.bp-treepg-popbtn {
    padding: 10px 16px;
    }
}


.align-centre{
  margin: 0 auto;  
}
