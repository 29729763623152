section .container {
    width: 100%;
    max-width: 960px;
}
section.bp-ap-res {
    padding: 50px 0 30px 0;
}
.bp-ap-res p, .bp-ap-res li {
    color: #FFF;
    font-size: 18px;
    margin-top: 20px;
}
.bp-ap-res-img {
    display: flex;
    flex-direction: column;
}
.bp-ap-res-img img {
    width: 100%;
    max-width: 170px;
    margin: 10px;
}
.bp-ap-res-img-inner {
    display: flex;
    align-items: center;
    justify-content: center;
}
.bp-ap-res-cont {
    width: 100%;
    max-width: 500px;
}
.useful-links h2 { 
    text-align:center; 
}
.bp-ap-res h5 {     
    font-weight:bold;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #fff;
}
.bp-useful-link a { 
    color:#fff; 
} 
.bp-ap-res-cont h2 .br {
    display: block;
}
/*Responsive*/
@media screen and (max-width:991px) {
.bp-ap-res .col-md-7,.bp-ap-res .col-md-5 {
    width: 100%;
}
.bp-ap-res-cont {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
}
.bp-ap-res-img {
    padding-top: 15px;
    max-width: 600px;
    margin: 0 auto;
}
.bp-ap-res-img img {
    width: 50%;
    max-width: unset;
    margin: 0;
    padding: 10px;
}
.bp-ap-res-img-inner {
    display: block;
    float: left;
    width: 100%;
}
}
@media screen and (max-width:767px) {
section.bp-home-resinfo h2 {
    font-size: 26px !important;
}
}