.bp-video-sec {
    padding: 70px 0px 50px 0px;
}
.video-cont {
    max-width: 550px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 40px;
}
.video-cont p {
    font-size: 20px;
}
.bp-video-sec .video-js {
    margin: 0px auto;
}
.bp-video-sec .vjs-poster {
    background-color: #1a2a15;
    border-radius: 5px;
}
.bp-video-sec .video-js .vjs-big-play-button {
    font-size: 100px;
    border: 0px;
    background-color: transparent;
    color: #C3FD48;
}
.bp-video-sec .video-js:hover .vjs-big-play-button, 
.bp-video-sec .video-js .vjs-big-play-button:focus {
    background-color: transparent;
}
.bp-video-sec .video-js .vjs-big-play-button:hover {
    background: transparent;
}
@media screen and (max-width:767px){
    .bp-video-sec .video-js {
        width: auto;
        height: 300px;
    }
    .bp-video-sec {
        padding-bottom: 20px;
    }
    .bp-video-sec .video-js .vjs-big-play-button {
        font-size: 70px;
    }
}