.bp-itthfi-main {
  background-image: url(../../assets/images/main-shelf-min.jpg);
  /*background-image: url(../../assets/images/treehousefibg.jpg);*/
  min-height: 750px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
}
.bp-itthfi-main .react-tabs__tab-panel {
  background-image: url(../../assets/images/shelfi.png);
  background-size: 100% 100%;
  padding-bottom: 29px;
}
.bp-itthfi-btn {
  background: rgba(203, 211, 32, 0.1);
  border-radius: 10px;
  color: #fff;
  list-style: none;
  display: inline-block;
  padding: 8px 25px;
  margin: 5px;
  font-weight: 600;
}
.bp-itthfi-ape a {
  position: relative;
}
.bp-itthfi-ape p {
  margin: 0;
}
.bp-itthfi-main .col-md-5 {
  padding: 0;
}
.bp-itthfi-btn {
  cursor: pointer;
}

.bp-tree-apena-modal{
  color: #172F26;
  font-size: 18px;
  margin-top: 15px;
  font-family: 'monument_extendedregular';
  line-height: 1.5;
}

ul.bp-itthfi-apli-modal li {
    float: left;
    width: 33.33%;
    /* padding-bottom: 20px; */
}
ul.bp-itthfi-apli-modal {
    list-style: none;
    padding: 0;
    overflow: auto;
}
.cl-succ-mess-modal {
    float: left;
    width: 70%;
    text-align: left;
}
.cost-para{
  padding: 0px 0 0 55px;
}
a.bp-treepg-popbtn-modal {
    background-color: #C3FD48;
    padding: 10px 87px;
    border-radius: 5px;
    font-size: 18px;
    line-height: 21px;
    color: #08130F;
    text-align:center;
    font-family: 'gothambold';
}
a.bp-treepg-popbtn-modal:hover {
    background-color: #85bb14;
}

.modal-heading{
  color: #172f26;
  font-size: 28px;
  margin-top: 15px;
  font-family: 'monument_extendedregular';
  line-height: 1.5;
}
.modal-heading1{
  color: #172f26;
    font-size: 28px;
    /* margin-top: 15px; */
    font-family: 'monument_extendedregular';
    line-height: 1.5;
}

.cl-ape-img-frame.v1{
    top: 40px;
}
.small-para{
    font-size: 14px;
    margin-top: 10px;
}
.money-img {
    margin-bottom: 15px;
}
.money-img b,.money-img img {
  margin-left: 10px;
}


ul.bp-itthfi-btnlist {
  background: #172f26;
  border-radius: 10px;
  /* list-style: unset; */
  padding: 8px 4px 7px 8px;
  margin-right: 15px;
  margin-left: 25px;
}
.bp-itthfi-btn.react-tabs__tab--selected {
  background-color: #C3FD48;
  color: #172f26;
}
.bp-itthfi-btntab {
  margin-top: 40px;
}
.bp-itthfi-ape:before {
  content: " ";
  background-image: url(../../assets/images/filfarme.png);
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 110px;
}

.bp-itthfi-ape-modal:before {
  content: " ";
  background-image: url(../../assets/images/filfarme.png);
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: 100%;
  background-repeat: no-repeat;
  /*width: 124px;*/
  width: 110px;
}
.bp-itthfi-ape img {
 /* width: 125px;
  height: 125px;*/
  width: 110px;
  height: 110px;
  padding: 10px;
  object-fit: cover;
}
.bp-itthfi-ape-modal {
    text-align: center;
    position: relative;
    cursor: pointer;
}
ul.bp-itthfi-apli {
  list-style: none;
  padding: 0;
  overflow: auto;
  padding: 0px 0 0 55px;
}
.bp-itthfi-ape {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.bp-itthfi-ape p {
  color: #fff;
}
ul.bp-itthfi-apli li {
  float: left;
  width: 33.33%;
  padding-bottom: 20px;
  padding-top: 12px;
}
ul.bp-itthfi-apli {
   height: 500px;
}
ul.bp-itthfi-apli::-webkit-scrollbar {
  width: 8px;
}
/* Track */
ul.bp-itthfi-apli::-webkit-scrollbar-track {
  background: rgb(203 211 32 / 25%);
}
/* Handle */
ul.bp-itthfi-apli::-webkit-scrollbar-thumb {
  background: #C3FD48;
  border-radius: 50px;
}
.bp-itthfi-main .react-tabs__tab-panel {
  padding-top: 45px;
}

/*Responsive*/
@media screen and (max-width: 1250px) {
  .bp-itthfi-main .col-md-5,
  .bp-itthfi-main .col-md-7 {
    width: 50%;
  }
}
@media screen and (max-width: 991px) {
  .bp-itthfi-main .col-md-5 {
    width: 100%;
  }

  .bp-itthfi-main .col-md-7 {
    display: none;
  }

  .bp-itthfi-btntab {
    max-width: 540px;
    width: 100%;
    float: right;
  }
  .bp-itthfi-main .react-tabs__tab-panel {
    padding-right: 8px;
  }
}
@media screen and (max-width: 767px) {
  .cl-ape-img-frame.v1 {
      top: 0px;
  }
}
@media screen and (max-width: 550px) {
  ul.bp-itthfi-apli {
    padding: 0px 0 0 20px;
  }
  ul.bp-itthfi-btnlist {
    margin-left: 15px;
  }
  ul.bp-itthfi-apli li {
    width: 50%;
  }
}
