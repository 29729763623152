section.bp-ntfp-main .container {
  max-width: 1200px;
}
section.bp-ntfp-main {
  padding: 70px 0 70px 0;
}
.bp-ntfp-ape:before {
  content: " ";
  background-image: url(../../assets/images/bigframe.png);
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 320px;
}
.bp-ntfp-ape img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bp-ntfp-ape {
  position: relative;
  padding: 30px 27px 20px 30px;
  width: 320px;
  height: 320px;
}
.bp-ntfp-cont h4 {
  color: #c3fd48;
  font-size: 20px;
  font-family: "monument_extendedregular";
  line-height: 1.5;
}
.bp-ntfp-cont h2 {
  color: #fff;
  font-size: 28px;
  font-family: "monument_extendedregular";
  line-height: 1.5;
  margin: 0;
}
.bp-ntfp-cont p {
  color: #fff;
  font-size: 25px;
  font-family: "monument_extendedregular";
  line-height: 1.5;
}
ul.bp-ntfp-det li {
  padding: 0;
  display: inline-block;
  padding-right: 45px;
}
ul.bp-ntfp-det li:nth-child(4) {
  padding-right: 0;
}
ul.bp-ntfp-det {
  padding: 0 0 10px 0;
}
.bp-ntfp-btn {
  background-color: #c3fd48;
  color: #08130f;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 5px;
  transition: 0.2s;
  border: unset;
  min-width: 150px;
  font-family: "gothambold";
}
.bp-ntfp-btn:hover {
  background-color: #85bb14;
}
ul.bp-ntfp-pop {
  padding: 20px 0 0 0;
}
ul.bp-ntfp-pop li {
  display: inline-block;
  padding-right: 15px;
  padding-bottom: 15px;
}
ul.bp-ntfp-pop li:nth-child(4) {
  padding-right: 0;
}
.bp-ntfp-form input {
  background: #c3fd4882;
  border-radius: 10px;
  border: none;
  width: 100%;
  color: #172f26;
  font-size: 20px;
  min-height: 60px;
  padding: 5px 15px 5px 15px;
}
.bp-ntfp-form input::placeholder {
  color: #172f26;
}
.bp-ntfp-form-sub input[type="submit"] {
  background-color: #c3fd48;
  color: #172f26;
  font-size: 16px;
  padding: 5px 40px;
  border-radius: 5px;
  transition: 0.2s;
  border: unset;
  float: right;
  font-family: "gothambold";
}
.bp-ntfp-form-sub input[type="submit"]:hover {
  background-color: #85bb14;
}
.bp-ntfp-form-sub label {
  color: #172f26;
  font-size: 20px;
  font-family: "monument_extendedregular";
}
.bp-ntfp-form-sub label span {
  font-family: "gothambook";
  font-size: 16px;
  font-weight: 600;
}
.bp-ntfp-form-sub label img {
  margin-top: -5px;
}
.bp-ntfp-form-sub {
  padding: 25px 0 15px 0;
}
section.bp-ntfp-main {
  padding: 80px 0 85px 0;
}
.bp-ntfp-form textarea {
  background: #c3fd4882;
  border-radius: 10px;
  border: none;
  width: 100%;
  color: #172f26;
  font-size: 20px;
  min-height: 60px;
  padding: 5px 15px 5px 15px;
  min-height: 151px;
}
.bp-ntfp-form textarea::placeholder {
  color: #172f26;
}
.bp-ntfp-form-c label {
  color: #172f26;
  font-size: 26px;
  font-family: "monument_extendedregular";
  line-height: 1.5;
  text-align: center;
  width: 100%;
  padding: 10px 0 0 0;
}
.bp-ntfp-formc-sub input[type="submit"] {
  background-color: #c3fd48;
  color: #172f26;
  font-size: 16px;
  padding: 5px 40px;
  border-radius: 50px;
  transition: 0.2s;
  border: unset;
  font-family: "gothambold";
}
.bp-ntfp-formc-sub input[type="submit"]:hover {
  background-color: #85bb14;
}
.bp-ntfp-formc-sub {
  text-align: center;
  margin-top: 40px;
}
.bp-ntfp-form-dob input {
  text-transform: uppercase;
}

/*Responsive*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bp-ntfp-ape {
    padding: 30px 17px 20px 23px;
    width: 240px;
    height: 240px;
  }
  .bp-ntfp-ape:before {
    width: 240px;
  }
}
@media screen and (max-width: 767px) {
  .bp-ntfp-ape {
    margin-bottom: 40px;
  }
  ul.bp-ntfp-det li {
    padding-bottom: 20px;
  }
  .bp-ntfp-cont h2 {
    font-size: 28px;
  }
}
