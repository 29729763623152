.bp-tree-main h1 {
    color: #C3FD48;
    font-size: 36px;
    line-height: 1.5;
}
section.bp-tree-main {
    padding: 50px 0 50px 0;
}
section.bp-tree-main p {
    color: #fff;
}
section.bp-tree-main .bp-tree-btn {
    background-color: #C3FD48;
    width: 100%;
    color: #08130F;
    font-size: 16px;
    padding: 5px 25px;
    border-radius: 5px;
    transition: .2s;
    border: unset;
    font-family: 'gothambold';
}
section.bp-tree-main .bp-tree-btn:hover{
    background-color: #85bb14;
}
section.bp-tree-main ul {
    list-style: none;
    padding: 0;
}
section.bp-tree-main ul li {
    display: inline-block;
    padding-right: 25px;
}
.popup-content {
    background: #fff;
    margin: auto;
    width: 100%;
    border-radius: 5px;
    max-width: 580px;
}
.modal {
    display: contents;
    position: unset;
}
.popup-overlay {
    background: rgba(0,0,0,.5);
}
.header {
    background-color: #C3FD48;
    color: #172F26;
    font-size: 26px;
    font-family: 'monument_extendedregular';
    line-height: 1.5;
    border-radius: 5px 5px 0px 0px;
    padding: 10px 35px 10px 25px;
}
button.close {
    position: absolute;
    right: 11px;
    top: 20px;
    background-color: unset;
    border: unset;
    font-size: 50px;
    line-height: 32px;
    color: #172F26;
}
.modal .content {
    padding: 25px 25px 25px 25px;
}
.bp-mod-form label {
    width: 100%;
    font-size: 16px;
    font-family: 'monument_extendedregular';
    color: #172F26;
    font-weight: 600;
}
.bp-mod-form {display: inline-block;width: 43%;margin: 0 10px 0 10px;}
form.bp-mod-form-thg {
    text-align: center;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
}
.bp-mod-form input {
    width: 100%;
    background-color: rgb(203 211 32 / 50%);
    border: unset;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    text-align: center;
    font-size: 20px;
    font-family: 'monument_extendedregular';
}
.modal .content p {
    color: #172F26;
    text-align: center;
}
.bp-mod-form-sub input {
    background-color: #C3FD48;
    color: #172F26;
    font-size: 18px;
    font-weight: 700;
    padding: 5px 50px;
    border-radius: 50px;
    transition: .2s;
    border: unset;
    margin: 15px 0 0 0;
}
@media screen and (max-width:767px) {
    section.bp-tree-main ul li{
        padding-bottom: 15px;
    }
    .bp-tree-main h1 {
        font-size: 26px;
    }
}
@media screen and (max-width:580px) {
    .popup-content {
        margin-left: 10px !important;
        margin-right: 10px !important;
    } 
}
@media screen and (max-width:480px) {
    .bp-mod-form {
        margin: 0;
        width: 100%;
        padding-bottom: 10px;
    }
    .header {
        font-size: 30px;
    }
    button.close {
        top: 14px;
    }
    section.bp-tree-main ul li {
        display: block;
        margin-bottom: 15px;
    }
    section.bp-tree-main .bp-tree-btn {
        width: auto;
    }
    
}