section.bp-bnbarmi-main h4 {
    color: #e8f0f7;
    padding: 10px 0 0;
    font-size: 20px;
    font-family: "monument_extendedregular";
}

section.bp-bnbarmi-main h4 span {
    color: #C3FD48;
}
section.bp-bnbarmi-main a.polycontract_link {
    text-decoration: underline;
    color: #fff;
    font-size: 18px;
    margin-top: 20px;
}

@media screen and (max-width:767px) {
    section.bp-bnbarmi-main p.polycontract_text {
        word-break: break-all;
    }
}