.bp-home-vidbanner video {
    width: 100%;
    object-fit: cover;
    height: 100%;
    margin: 0;
    padding: 0;
}
.bp-home-vidbanner {
    padding: 0;
    margin-bottom: -10px;
    margin-top: -2px;
    position: relative;
}
.bp-home-vidbanner .treehouse_btn {
    position: absolute;
    bottom: 50px;
    transform: translate(-50%, -50%);
    left: 50%;
    font-size: 14px;
    line-height: 30px;
    transition: .2s;
    color: #08130F;
    background: #C3FD48;
    border-radius: 5px;
    padding: 9px 30px 9px 30px;
    min-width: 200px;
    display: block;
    text-align: center;
    font-family: 'gothambold';
}
.bp-home-vidbanner .treehouse_btn:hover {
    background-color: #85bb14;
}
