header {
    top: 0;
    background: #08130f;
    padding-top: 7px;
    padding-bottom: 7px;
    position: fixed;
    width: 100%;
    z-index: 99;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 32%), 0px 1px 1px 0px rgb(0 0 0 / 41%), 0px 1px 3px 0px rgb(0 0 0 / 49%);
}
.connect-wallet,.connect-wallet p {
   text-align: center !important;
}

.hide {
  display: none;
  color: #fff;
  text-align: center;
  padding: 9px 10px;
}
.hide-show:hover  .hide {
  display: block;
}
/*.hide-show.disconnect-wrapper:hover {
    border: 1px solid #C3FD48;
}

/*.hide-show.bp-main-mm:hover .disconnect-wrapper {
    display: block;
}
.hide-show.bp-main-mm:hover .bp-main-mm-desk,.hide-show.bp-main-mm:hover .bp-main-mm-mob{
    display:none;
}*/
.hide-show {
    border: 1px solid #C3FD48;
    border-radius: 4px;
}
.disconnect-wrapper div.hide {
    color: #ffff;
    margin: 0px;
}
.disconnect-wrapper {
    color: black;
    border-radius: 4px;
    padding: 9px 10px;
    cursor: pointer;
    background-color: #C3FD48;
    font-weight: 500;
}
.connect-wallet{
 padding: 10px !important;
}
p.grey-text {
    color: #a0a3af !important;
    padding: 0px !important;
}
.row.bor {
    border-top: 1px solid #a0a3af;
}
.bp-main-logo a {
    color: #fff;
}
.bp-main-mm-mob{
    display:none;
}
.bp-main-nav a {
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    font-family: 'gothambook';
}
a.nav-link {
    padding: 1px 10px;
}
.bp-main-nav a:hover {
    color: #fff;
}
.bp-main-soic ul {
    display: flex;
    list-style: none;
    padding: 0px 8px;
    margin-bottom: 0px;
}
.bp-main-soic img {
    max-width: 25px;
}
.bp-main-soic ul li {
    padding: 0 7px;
}
.bp-main-mm a {
    transition: .2s;
    background-color: #C3FD48;
    color: #08130F;
    padding: 10px 16px;
    font-family: 'gothambold';
    font-size: 14px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    line-height: 1.75;
    border-radius: 5px;
}
.bp-main-mm a img {
    margin-right: 8px;
}
.bp-main-sec,.bp-main-nav-sec {
    display: flex;
    align-items: center;
}
.bp-main-logo-sec {
    flex-grow: 1;
}
.bp-main-soic ul:before {
    content: "|";
    color: #7A7B74;
    padding: 0px 8px 0px 0px;
}
.bp-main-logo {
    padding: 0px 0;
}
.bp-main-mm a:hover {
    background-color: #85bb14;
}
section.bp-home-resinfo {
    padding: 50px 0 50px 0;
}
footer {
    background-color: #08130F;
    border-top: 1px solid #7a7b7478;
    padding: 50px 0 25px 0;
    text-align: center;
}
footer h2 {
    color: #fff;
    /* padding-bottom: 10px; */
}
a.verified_link {
    color: #fff;
    font-size: 18px;
    margin-top: 20px;
}
footer ul li {
    display: inline-flex;
    padding: 7px;
}
footer ul li img {
    max-width: 25px;
}
footer ul {
    padding: 5px 0px 5px 0px;
    margin: 0;
}
/*Mobile*/
.overlay-open {
    display: block !important;
}
#mySideoverlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
}
.mm-open{
    width: 250px !important;
}
.sidenav-menu{
    width:0px;
}
#mySidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #172F26;
    overflow-x: hidden;
    transition: .5s;
}
.menu-btn.mph-home-mobile-menu {
    display: none;
}
ul.side-bar-menu a {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
}
ul.side-bar-menu {
    padding: 0;
}
ul.side-bar-menu li {
    padding: 6px 0 6px 0;
}
.bp-main-menu-mob {
    padding: 20px 15px 0 15px;
}
.bp-main-soic-mob img {
    max-width: 20px;
}
.bp-main-soic-mob ul {display: flex;padding: 0;list-style: none;}

.bp-main-soic-mob {
    padding: 0px 15px 0 15px;
}
.bp-main-soic-mob ul li {
    padding-right: 15px;
}

/*a.footer_logo_main {
    width: 115px;
    display: block;
    margin: 0 auto;
}
a.footer_logo_main img {
    width: 100%;
}*/

/*Responsive*/
@media screen and (max-width:1200px) {
    .bp-main-nav a {
        font-size: 16px;
    }
    .bp-main-logo h1 {
        padding-top: 5px;
    }
}
@media screen and (max-width:1050px) {
.bp-main-nav,.bp-main-soic {
        display: none;
    }
    .menu-btn.mph-home-mobile-menu {
        display: block;
        color: #fff;
        position: absolute;
        top: 19px;
        left: 22px;
        font-size: 30px;
    }

    .bp-main-logo {
        padding-left: 55px;
    }
    .bp-main-logo {
        /*padding-top: 0px !important;*/
    }
    .bp-main-logo h1 {
        padding-top: 0px;
    }
}
@media screen and (max-width:1024px) {
    header {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    main {
        padding-top: 70px;
    }
}
@media screen and (max-width:991px) {
    .bp-main-nav,.bp-main-soic {
        /*display: none;*/
    }
    
    footer h2 {
        font-size: 30px;
    }
    a.verified_link {
        font-size: 16px;
    }
}
@media screen and (max-width:991px) {

}


@media screen and (max-width:480px) {
    .bp-main-nav-sec {
        /* display: none; */
        padding: 8px 0 0 0;
    }
    a.bp-main-mm-desk {
        display: none;
    }
    a.bp-main-mm-mob {
        display: block;
    }
    .menu-btn.mph-home-mobile-menu {
        top: 15px;
    }

}
@media screen and (max-width:420px) {
   .disconnect-wrapper {
    text-align: center;
   }
}
@media screen and (max-width:350px) {
    .bp-main-logo {
        padding-left: 45px;
    }
    .menu-btn.mph-home-mobile-menu {
        left: 16px;
    }
}