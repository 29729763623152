section.bp-home-treeh {
    text-align: center;
}
section.bp-home-treeh p {
    color: #fff;
    max-width: 700px;
    margin: 20px auto 0px auto;
}
section.bp-home-treeh {
    padding: 40px 0px 40px 0px;
}