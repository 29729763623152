.bp-bnbarsc-main h3 {
    color: #C3FD48;
    text-align: center;
    padding-bottom: 15px;
}
.bp-bnbarsc-main {
    background: rgb(255 255 255 / 10%);
    border-radius: 5px;
    padding: 15px 15px 15px 15px;
}
.bp-bnbarsc-main p {
    color: #fff;
    text-align: center;
}
section.bp-bnbarsc {
    padding: 10px 0 60px 0;
}
.banana-bar-box{
    text-align: center;
    margin: 0px auto;
}
@media screen and (max-width:767px) {
    .bp-itth-inbx-head h2 {
        font-size: 24px;
    }
    .bp-itth-inbx-cont.v2 h4 {
        max-width: 100%;    
    }
}
@media screen and (max-width:600px) {
	a.bp-treepg-popbtn-modal {
    	padding: 10px 30px;
	}
}
@media screen and (max-width:480px) {
	.banana-bar-box{
		width:auto;
	}
}