.bp-migr-main h1 {
    color: #C3FD48;
    font-size: 36px;
    font-family: 'monument_extendedregular';
    line-height: 1.5;
}
section.bp-migr-main ul li {
    color: #FFF;
    font-size: 18px;
    padding: 8px 0 8px 15px;
    display: inline-block;
    position: relative;
    list-style: none;
}
section.bp-migr-main {
    text-align: center;
    padding: 50px 0px 0px 0px;
}
section.bp-migr-main ul li:before {content: "";width: 8px;height: 8px;background-color: white;position: absolute;border-radius: 50px;top: 18px;left: 0px;}
section.bp-migr-main ul {
    padding: 0;
    width: 100%;
    max-width: 630px;
    margin: 0 auto;
}