section.bp-home-team img {
    width: 100%;
    border-radius: 5px;
}
section.bp-home-team h3 {
    font-size: 18px;
    color: #C3FD48;
    text-align: center;
}
section.bp-home-team p {
    color: #FFF;
    font-size: 18px;
    font-family: 'gothambold';
    text-align: center;
    margin-bottom: 0;
}
.bp-home-team-sec {
    float: left;
    width: 20%;
}
.bp-home-team-sec {
    float: left;
    width: 20%;
}
section.bp-home-team h2 {
    text-align: center;
    padding-bottom: 20px;
}
section.bp-home-team {
    padding: 40px 0 50px 0;
}

/*Responsive*/
@media screen and (max-width:991px) {
    .bp-home-team-sec {
    width: 33.33%;
    padding-bottom: 25px;
}
}
@media screen and (max-width:767px) {
    .bp-home-team-sec {
    width: 50%;
    padding-bottom: 25px;
}
}
