/*@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');*/

@font-face {
    font-family: 'gothambook';
    src: url('./assets/fonts/gotham_book_font-webfont.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gothambold';
    src: url('./assets/fonts/gotham-bold-webfont.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'monument_extendedregular';
    src: url('./assets/fonts/monumentextended-regular-webfont.ttf');
    font-weight: normal;
    font-style: normal;
}

/*@font-face {
  font-family: "JungleLand";
  src: url("./assets/fonts/JungleLand.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}*/

body{
	font-size: 16px;
	font-family: 'gothambook';
    line-height: 1.4;
}
a,a:hover,a:focus {
    text-decoration: none;
}
.container {
    max-width: 1200px;
}
main {
    padding-top: 80px;
}
h1 {
    font-family: 'monument_extendedregular';
}
h2 {
    color: #C3FD48;
    font-size: 36px;
    font-family: 'monument_extendedregular';
    line-height: 1.4;
}
h3 {
    color: #FFF;
    font-size: 28px;
    margin-top: 15px;
    font-family: 'monument_extendedregular';
    line-height: 1.4;
}
section {
    background-color: #08130F;
    margin-top: -2px;
}
p {
    color: #000;
    font-size: 18px;
}
/*Responsive*/
@media screen and (max-width:767px) {
    h2 {
        font-size: 26px;
        line-height: 1.3;
    }
    h3 {
        font-size: 20px;
        line-height: 1.3;
    }
}