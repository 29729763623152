section.bp-tree-ape {
    padding: 50px 0px 50px 0px;
}
section.bp-tree-ape ul {
    list-style: none;
    padding: 0;
}
span.bp-tree-apeid,span.bp-tree-apena {
    color: #fff;
    font-size: 18px;
    font-family: 'monument_extendedregular';
}
section.bp-tree-ape span {
       position: absolute;
    width: 100%;
    text-align: center
}
section.bp-tree-ape ul li {
    float: left;
    width: 23%;
    position: relative;
    margin: 6px;
    margin-bottom: 25px;
}
section.bp-tree-ape li:before {
    content: " ";
    background-image: url(../../assets/images/Frame_boards.png);
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
}
section.bp-tree-ape img {
    width: 100%;
    padding: 35px;
}
span.bp-tree-apena {
    bottom: 12px;
    left: 0;
}
span.bp-tree-apeid {
    top: 19px;
    left: 0;
}
@media screen and (max-width:991px) {
    section.bp-tree-ape ul li {
    width: 31.33%;
    }
    span.bp-tree-apeid {
        top: 10%;
    }
    span.bp-tree-apena {
        bottom: 6%;
    }
}
@media screen and (max-width:991px) {
    section.bp-tree-ape ul li {
        width: 47%;
    }
    span.bp-tree-apena {
        bottom: 7%;
    }
}
@media screen and (max-width:600px) {
    section.bp-tree-ape ul li {
        width: 100%;
        margin: 0;
        margin-bottom: 25px;
    }
    section.bp-tree-ape ul {
        max-width: 300px;
        margin: 0 auto;
    }
}
