section.bp-itth-main h1 {
    color: #C3FD48;
    font-size: 36px;
    line-height: 1.5;
}
section.bp-itth-main {
    padding: 70px 0 70px 0;
}
section.bp-itth-main p {
    color: #fff;
}
.bp-itth-inbx-head h2 {
    font-size: 30px;
}
.bp-itth-inbx {
    background: rgb(255 255 255 / 15%);
    border-radius: 15px;
    padding: 25px;
}
.bp-itth-inbx-head h2 span {
    float: right;
    color: #fff;
}
.bp-itth-inbx-head {
    border-bottom: 1px solid rgb(255 255 255 / 32%);
    padding: 4px 0 5px 0;
}
.bp-itth-inbx-cont h4 {
    color: #C3FD48;
    font-size: 20px;
    font-family: 'monument_extendedregular';
    line-height: 1.5;
    margin: 0;
}
.bp-itth-inbx-cont.v2 h4 {
    max-width: 180px;
    font-size: 16px;
}
.bp-itth-inbx-cont h3 {
    font-size: 27px;
    margin: 0;
}
.bp-itth-inbx-cont.v2 h3 {
    font-size: 22px;
}
.bp-itth-inbx-cont ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
section.bp-itth-main .container {
    max-width: 1200px;
}
.bp-itth-inbx-cont ul li {
    display: inline-block;
    padding-bottom: 10px;
    vertical-align: top;
}
.bp-itth-inbx-cont.v2 ul li {
    width: 50%;
}
.bp-itth-inbx-cont {
    padding: 20px 0px 15px 0px;
}
.bp-itth-inbx-btn {
    padding: 0 0 15px 0;
}
a.bp-itth-btn {
    color: #08130F;
    font-size: 16px;
    line-height: 19px;
    background-color: #C3FD48;
    padding: 10px 30px;
    border-radius: 5px;
    transition: .2s;
    font-family: 'gothambold';
}
a.bp-itth-btn:hover {
    background-color: #85bb14;
}
a.bp-itth-btn img {
    margin-top: -4px;
    margin-right: 5px;
}

/*Responsive*/

@media screen and (max-width:1060px) {
    section.bp-itth-main .col-md-5,section.bp-itth-main .col-md-7 {
        width: 50%;
    }
}
@media screen and (max-width:991px) {
    section.bp-itth-main .col-md-5, section.bp-itth-main .col-md-7 {
        width: 100%;
    }
    section.bp-itth-main .col-md-7 {
        padding-bottom: 25px;
    }
    .bp-itth-inbx {
        max-width: 500px;
        margin: 0 auto;
    }
    section.bp-itth-main h1 {
        text-align: center;
    }
    section.bp-itth-main p {
        max-width: 800px;
        margin: 0 auto;
        text-align: center;
    }
}
@media screen and (max-width:767px) {
    section.bp-itth-main h1 {
        font-size: 26px;
    }
    .bp-itth-inbx-cont h4 {
        max-width: 100%;
    }
    .bp-itthgs-insec {
        width: 100%;
        padding-bottom: 20px;
        text-align: center;
    }
    .bp-itthgs-main {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }
    .bp-itthgs-insec {
        width: 100%;
    }
}
@media screen and (max-width:767px) {
    .bp-itth-inbx-cont ul li {
        width: 100%;
    }
    .bp-itth-inbx-cont.v2 ul li {
        width: 100%;
    }
    .bp-itth-inbx-cont h4 {
        font-size: 22px;
    }
    .bp-itth-inbx-cont h3 {
        font-size: 22px;
    }
}