section.bp-itthgs {
    padding: 70px 0 70px 0;
}
.bp-itthgs-main {
    background: rgb(255 255 255 / 15%);
    border-radius: 15px;
    overflow: auto;
     padding: 30px 30px 30px 30px;
}
.bp-itthgs-main h1 {
	color: #C3FD48;
	font-size: 36px;
	line-height: 1.5;
	text-align: center;
    padding: 0px 0px 10px 0px;
 }
.bp-itthgs-insec p {
    color: #fff;
    font-size: 14px;
    font-family: 'monument_extendedregular';
    line-height: 1.5;
    margin: 0;
    padding-bottom: 8px;
}
.bp-itthgs-insec {
    float: left;
    width: 33.33%;
}
@media screen and (max-width:767px) {
    .bp-itthgs-insec {
        width: 100%;
        padding-bottom: 20px;
        text-align: center;
    }
    .bp-itthgs-main h1 {
        font-size: 26px;
    }
}