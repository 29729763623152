ul.featured_images li {
    list-style: none;
    display: inline-block;
    width: 33%;
    text-align: center;
}
section.bp-home-fo h2 {
    text-align: center;
    font-size: 24px;
    padding-bottom: 15px;
}
section.bp-home-fo {
    padding: 40px 0 50px 0;
}
ul.featured_images img {
    width: auto;
    max-width: 100%;
}
ul.featured_images {
    padding: 0;
}
/*Responsive*/
@media screen and (max-width:991px) {
ul.featured_images li {
    width: 100%;
    padding-bottom: 25px;

}
section.bp-home-fo {
    padding: 20px 0 22px 0;
}
}
@media screen and (max-width:767px) {
section.bp-home-fo h2 {
    font-size: 26px;
}
}