section.bp-bnbar-main h1 {
    color: #C3FD48;
    font-size: 36px;
    font-family: 'monument_extendedregular';
    line-height: 1.5;
}
section.bp-bnbar-main {
    text-align: center;
    padding: 30px 0 40px 0;
}
section.bp-bnbar-main p {
    color: #fff;
    margin: 0 auto;
    padding-bottom: 15px;
}
section.bp-bnbar-main img {
    width: 100%;
    max-width: 400px;
}
/*Responsive*/
@media screen and (max-width:767px) {
    section.bp-bnbar-main h1 {
        font-size: 26px;
    }
    section.bp-bnbar-main {
        padding: 30px 0 25px 0;
    }
}
