section.apevolution-sec {
    padding: 50px 0px;
}
.apevolution-sec .col-md-7 {
    text-align: center;
    margin: 0 auto;
}
.apevolution-cont h2 {
    font-size: 24px;
    margin-bottom: 15px;
}
.apevolution-btn{
    list-style: none;
    padding: 0px;
    padding-top: 10px;
    margin-bottom: 0px;
}
.apevolution-btn li {
    display: inline-block;
    padding-right: 24px;
}
.apevolution-btn a {
    font-size: 14px;
    line-height: 30px;
    transition: .2s;
    color: #08130F;
    background: #C3FD48;
    border-radius: 5px;
    padding: 9px 30px 9px 30px;
    min-width: 200px;
    display: block;
    text-align: center;
    font-family: 'gothambold';
}
.apevolution-btn li a:hover {
    background-color: #85bb14;
}
.apevolution-btn img {
    margin-right: 8px;
}
@media screen and (max-width:991px) {
    .apevolution-btn li {
        padding: 0px 8px 15px 8px;
    }
}
@media screen and (max-width:767px) {
    section.apevolution-sec {
        padding: 10px 0px;
    }
}
