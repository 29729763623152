.bp-home-movingbanner{
    -webkit-animation-duration: 20000s;
    animation-duration: 20000s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    /*background: url(../../assets/images/banner-moving.jpg) 0 0/cover repeat #fff;*/
    background: url(../../assets/images/banner-moving-new.png) 0 0/cover repeat #fff;
    height: 80vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.bp-home-mbm {
    width: 100%;
    bottom: 0;
    height: 40px;
    position: absolute;
    background: rgba(195, 253, 72);
    box-shadow: 0px -10px 40px 40px #C3FD48;
}
@keyframes MOVE-BG{0%{background-position-x:0}to{background-position-x:-1000000px}}